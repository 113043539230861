// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChartToolTip {
    background-color: #888;
    color: #eee;
    padding: 8px;
    border-radius: 5px;
    opacity: .8;
}

.ChartToolTip > P {
    margin:0
}`, "",{"version":3,"sources":["webpack://./src/components/elements/charts/Chart.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI;AACJ","sourcesContent":[".ChartToolTip {\n    background-color: #888;\n    color: #eee;\n    padding: 8px;\n    border-radius: 5px;\n    opacity: .8;\n}\n\n.ChartToolTip > P {\n    margin:0\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
