// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ace-tomorrow .ace_reference {
    background-color: aliceblue;
    font-weight: 500;
    color: #1565c0;
    border-radius: 2px;
}`, "",{"version":3,"sources":["webpack://./src/components/elements/editors/JsonEditor.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,gBAAgB;IAChB,cAAc;IACd,kBAAkB;AACtB","sourcesContent":[".ace-tomorrow .ace_reference {\n    background-color: aliceblue;\n    font-weight: 500;\n    color: #1565c0;\n    border-radius: 2px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
