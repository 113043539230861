// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DataAnalytics {
    display: flex;
    flex-direction: column;
}
.DataAnalytics  > .Data {
    margin: 0 4px;
    display: flex;
    flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/DataAnalytics.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":[".DataAnalytics {\n    display: flex;\n    flex-direction: column;\n}\n.DataAnalytics  > .Data {\n    margin: 0 4px;\n    display: flex;\n    flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
