// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RulePane {
    margin-top: 20px;
    margin-left: 50px;
}

.RuleDescription {
    font-weight: 300;
}

.RuleChip {
    line-height: 2.5em;
    margin: 0 10px;
    font-weight: 500;
}

.RuleRawData H1 {
    font-size: 140%;
    font-weight: 300;
    text-transform: uppercase;
}

.RuleSwitch {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 5px ;
    margin: -1px 0;
}
.RuleSwitch DIV.RuleSwitchLabel {
    padding-left: 10px;
}
.RuleSwitch .RuleSwitchDescription{
    line-height: 2em;
}
.RuleSwitch .RuleSwitch{
    line-height: 1em;
}

.SmallTitle {
    font-size: 12px;
}

.SmallTitle PRE {
    margin:0
}`, "",{"version":3,"sources":["webpack://./src/components/elements/details/RuleDetails.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;IACX,eAAe;IACf,cAAc;AAClB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI;AACJ","sourcesContent":[".RulePane {\n    margin-top: 20px;\n    margin-left: 50px;\n}\n\n.RuleDescription {\n    font-weight: 300;\n}\n\n.RuleChip {\n    line-height: 2.5em;\n    margin: 0 10px;\n    font-weight: 500;\n}\n\n.RuleRawData H1 {\n    font-size: 140%;\n    font-weight: 300;\n    text-transform: uppercase;\n}\n\n.RuleSwitch {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n    padding: 0 5px ;\n    margin: -1px 0;\n}\n.RuleSwitch DIV.RuleSwitchLabel {\n    padding-left: 10px;\n}\n.RuleSwitch .RuleSwitchDescription{\n    line-height: 2em;\n}\n.RuleSwitch .RuleSwitch{\n    line-height: 1em;\n}\n\n.SmallTitle {\n    font-size: 12px;\n}\n\n.SmallTitle PRE {\n    margin:0\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
