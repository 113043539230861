// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `DIV.PropertyRow {
    overflow-wrap: anywhere;
    overflow: hidden;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;
    font-size: 15px;
    font-weight: 400;
    width: 100%;
    min-height: 40px;
}

DIV.PropertyRow:hover {
    background-color: rgba(200,200,200, 0.2);
}

DIV.PropertyRow DIV.FieldName {
    font-size: 16px;
    font-weight: 500;
    max-width: 330px;
    min-width: 120px;
    display: flex;
    align-items: center;
    padding-left: 5px;
    width: 250px;
}

DIV.PropertyRow DIV.FieldValue {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
}

DIV.PropertyRow DIV.FieldName SPAN {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

DIV.PropertyRow .FieldMore {
    cursor: pointer;
    margin-left: 10px;
    padding: 2px;
    width: 24px;
    height: 24px;
    border-radius: 5px;
}

DIV.PropertyRow .FieldMore:hover {
    cursor: pointer;
    margin-left: 10px;
    background-color: rgb(0, 136, 254);
    color: white;
}


`, "",{"version":3,"sources":["webpack://./src/components/elements/details/PropertyField.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,wCAAwC;AAC5C;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,kCAAkC;IAClC,YAAY;AAChB","sourcesContent":["DIV.PropertyRow {\n    overflow-wrap: anywhere;\n    overflow: hidden;\n    padding-top: 5px;\n    padding-bottom: 5px;\n    display: flex;\n    flex-direction: row;\n    font-size: 15px;\n    font-weight: 400;\n    width: 100%;\n    min-height: 40px;\n}\n\nDIV.PropertyRow:hover {\n    background-color: rgba(200,200,200, 0.2);\n}\n\nDIV.PropertyRow DIV.FieldName {\n    font-size: 16px;\n    font-weight: 500;\n    max-width: 330px;\n    min-width: 120px;\n    display: flex;\n    align-items: center;\n    padding-left: 5px;\n    width: 250px;\n}\n\nDIV.PropertyRow DIV.FieldValue {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 100%;\n    padding: 0 10px;\n}\n\nDIV.PropertyRow DIV.FieldName SPAN {\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n}\n\nDIV.PropertyRow .FieldMore {\n    cursor: pointer;\n    margin-left: 10px;\n    padding: 2px;\n    width: 24px;\n    height: 24px;\n    border-radius: 5px;\n}\n\nDIV.PropertyRow .FieldMore:hover {\n    cursor: pointer;\n    margin-left: 10px;\n    background-color: rgb(0, 136, 254);\n    color: white;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
