// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErrorBox {
    background: #d81b60;
    color: white;
    padding: 15px;
    overflow-x: auto;
    margin: 10px;
    display: flex;
    flex-direction: row;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: 0.4s;
}

.ErrorBox > .ErrorDetails {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    font-size: 120%;
    margin-top: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/errors/ErrorBox.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,4BAA4B;IAC5B,mCAAmC;IACnC,wBAAwB;AAC5B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,eAAe;IACf,aAAa;AACjB","sourcesContent":[".ErrorBox {\n    background: #d81b60;\n    color: white;\n    padding: 15px;\n    overflow-x: auto;\n    margin: 10px;\n    display: flex;\n    flex-direction: row;\n    animation-name: fadeInOpacity;\n    animation-iteration-count: 1;\n    animation-timing-function: ease-out;\n    animation-duration: 0.4s;\n}\n\n.ErrorBox > .ErrorDetails {\n    display: flex;\n    flex-direction: column;\n    margin-left: 10px;\n    font-size: 120%;\n    margin-top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
