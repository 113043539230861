// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DataBrowsingList {
    display: flex;
    flex-direction: column;
}

.DataBrowsingList > .Chart {
    height: 200px;
}

.DataBrowsingList > .Data {
}

`, "",{"version":3,"sources":["webpack://./src/components/pages/DataBrowsingList.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;AACjB;;AAEA;AACA","sourcesContent":[".DataBrowsingList {\n    display: flex;\n    flex-direction: column;\n}\n\n.DataBrowsingList > .Chart {\n    height: 200px;\n}\n\n.DataBrowsingList > .Data {\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
