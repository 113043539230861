// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `DIV.NodeSequence {
    border-radius: 30%;
    color: white;
    padding-right: 1px;
    position: absolute;
    top: -10px;
    right: 4px;
    font-size: 10px;
    display: flex;
    width: 17px;
    height: 17px;
    align-items: center;
    justify-content: center;
}

DIV.NodeAlert {
    border-radius: 50%;
    color: white;
    position: absolute;
    top: 13px;
    right: -30px;
    display: flex;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    background-color: white;
}

DIV.Ok {
    background-color: #43a047 !important;
}

DIV.ErrorSequence {
    background-color: #d81b60 !important;
}

DIV.Error {
    color: #d81b60 !important;
}

DIV.Warning {
    color: orange !important;
}`, "",{"version":3,"sources":["webpack://./src/components/flow/NodeAlerts.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,UAAU;IACV,UAAU;IACV,eAAe;IACf,aAAa;IACb,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,SAAS;IACT,YAAY;IACZ,aAAa;IACb,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;IACvB,uBAAuB;AAC3B;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":["DIV.NodeSequence {\n    border-radius: 30%;\n    color: white;\n    padding-right: 1px;\n    position: absolute;\n    top: -10px;\n    right: 4px;\n    font-size: 10px;\n    display: flex;\n    width: 17px;\n    height: 17px;\n    align-items: center;\n    justify-content: center;\n}\n\nDIV.NodeAlert {\n    border-radius: 50%;\n    color: white;\n    position: absolute;\n    top: 13px;\n    right: -30px;\n    display: flex;\n    width: 30px;\n    height: 30px;\n    align-items: center;\n    justify-content: center;\n    background-color: white;\n}\n\nDIV.Ok {\n    background-color: #43a047 !important;\n}\n\nDIV.ErrorSequence {\n    background-color: #d81b60 !important;\n}\n\nDIV.Error {\n    color: #d81b60 !important;\n}\n\nDIV.Warning {\n    color: orange !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
