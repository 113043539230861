// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IconButton {
    padding: 5px;
    border-radius: 12px;
    display: inline-grid;
    cursor: pointer;
    margin: 1px;
    width: 40px;
    height: 40px;
    align-content: center;
    justify-content: center;
}

.IconButtonRegular {
    color: #1565c0;
}

.IconButtonRegular:hover
{
    color: white;
    background-color:  #aaa;
}

.IconButtonSelected {
    color: white;
    background-color:  #1565c0;
}`, "",{"version":3,"sources":["webpack://./src/components/elements/misc/IconButton.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;IACnB,oBAAoB;IACpB,eAAe;IACf,WAAW;IACX,WAAW;IACX,YAAY;IACZ,qBAAqB;IACrB,uBAAuB;AAC3B;;AAEA;IACI,cAAc;AAClB;;AAEA;;IAEI,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,0BAA0B;AAC9B","sourcesContent":[".IconButton {\n    padding: 5px;\n    border-radius: 12px;\n    display: inline-grid;\n    cursor: pointer;\n    margin: 1px;\n    width: 40px;\n    height: 40px;\n    align-content: center;\n    justify-content: center;\n}\n\n.IconButtonRegular {\n    color: #1565c0;\n}\n\n.IconButtonRegular:hover\n{\n    color: white;\n    background-color:  #aaa;\n}\n\n.IconButtonSelected {\n    color: white;\n    background-color:  #1565c0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
