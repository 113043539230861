// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ObjectFiltering {
    padding: 5px 15px 0 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ObjectFiltering > .Input {
    flex: 1 1;
}
.ObjectFiltering > .Date {
    width: 245px;
}
.ObjectFiltering > .Action {
    display: flex;
}`, "",{"version":3,"sources":["webpack://./src/components/elements/forms/ObjectFiltering.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,SAAO;AACX;AACA;IACI,YAAY;AAChB;AACA;IACI,aAAa;AACjB","sourcesContent":[".ObjectFiltering {\n    padding: 5px 15px 0 15px;\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n}\n\n.ObjectFiltering > .Input {\n    flex: 1;\n}\n.ObjectFiltering > .Date {\n    width: 245px;\n}\n.ObjectFiltering > .Action {\n    display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
