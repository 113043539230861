// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JsonStringify {

}

.JsonStringify SPAN.JsonItem {
    margin-right: 7px;
    padding-left: 2px;
    line-height: 25px;
}
.JsonStringify SPAN.JsonItem:hover {
    background: aliceblue;
    border-radius: 3px;
}

.JsonStringify SPAN.JsonItem:hover SPAN.JsonValue {
    text-decoration: underline;
}

.JsonStringify SPAN.JsonItem:hover SPAN.JsonKey {
    background-color: #666;
    color: white;
}

.JsonStringify SPAN.JsonItem > SPAN.JsonValue {
    color: #555;
    padding: 1px 1px;
    border-radius: 3px;
    word-wrap: anywhere;
    color: rgb(0, 105, 192);
}

.JsonStringify SPAN.JsonItem > SPAN.JsonKey {
    background-color: #f0f0f0;
    color: #333;
    padding: 2px 4px;

    border-radius: 3px;
    margin:0 2px 0 0px;
}`, "",{"version":3,"sources":["webpack://./src/components/elements/misc/JsonStringify.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,iBAAiB;AACrB;AACA;IACI,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,gBAAgB;;IAEhB,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":[".JsonStringify {\n\n}\n\n.JsonStringify SPAN.JsonItem {\n    margin-right: 7px;\n    padding-left: 2px;\n    line-height: 25px;\n}\n.JsonStringify SPAN.JsonItem:hover {\n    background: aliceblue;\n    border-radius: 3px;\n}\n\n.JsonStringify SPAN.JsonItem:hover SPAN.JsonValue {\n    text-decoration: underline;\n}\n\n.JsonStringify SPAN.JsonItem:hover SPAN.JsonKey {\n    background-color: #666;\n    color: white;\n}\n\n.JsonStringify SPAN.JsonItem > SPAN.JsonValue {\n    color: #555;\n    padding: 1px 1px;\n    border-radius: 3px;\n    word-wrap: anywhere;\n    color: rgb(0, 105, 192);\n}\n\n.JsonStringify SPAN.JsonItem > SPAN.JsonKey {\n    background-color: #f0f0f0;\n    color: #333;\n    padding: 2px 4px;\n\n    border-radius: 3px;\n    margin:0 2px 0 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
