// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DetailKeyValue {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 5px ;
    margin: -1px 0;
    min-height: 15px;
}

.DetailKeyValue .DetailValue {
    flex: 1 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    padding-left: 10px;
    border-bottom: solid 1px #ddd;
}

.DetailKeyValue .DetailKey {
    flex: .5 1;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 10px;
    border-bottom: solid 1px #ddd;
}

`, "",{"version":3,"sources":["webpack://./src/components/elements/details/DetailKeyValue.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;IACX,aAAa;IACb,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,SAAO;IACP,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,kBAAkB;IAClB,6BAA6B;AACjC;;AAEA;IACI,UAAQ;IACR,WAAW;IACX,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,6BAA6B;AACjC","sourcesContent":[".DetailKeyValue {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n    padding: 5px ;\n    margin: -1px 0;\n    min-height: 15px;\n}\n\n.DetailKeyValue .DetailValue {\n    flex: 1;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    width: 100%;\n    padding-left: 10px;\n    border-bottom: solid 1px #ddd;\n}\n\n.DetailKeyValue .DetailKey {\n    flex: .5;\n    width: 100%;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    padding-left: 10px;\n    border-bottom: solid 1px #ddd;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
