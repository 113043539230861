// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MdManual {
    height: 100%;
    overflow: auto;
    padding: 10px;
    border-radius: 5px;
    -webkit-user-select: text;
            user-select: text;
    border-radius: inherit;
    color: #444;
}
.MdManual A {
    color: rgb(0, 105, 192);
}

.MdManual H1 {
    margin: 0;
    font-size: 20px;
    font-weight: normal;
    color: black;
}

.MdManual H2 {
    margin: 0;
    font-size: 18px;
    font-weight: normal;
    color: black;
}

.MdManual H3 {
    margin: 0;
    font-size: 17px;
    font-weight: normal;
    color: black;
}

.MdManual CODE {
    background: #e1f5fe;
    display: inline-flex;
    padding: 15px;
    margin-left: 10px;
    border-radius: 5px;
    border: solid 2px #000;
    color: black;
    font-family: "IBM Plex Mono";
    width: 100%;
    overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/flow/actions/MdManual.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,cAAc;IACd,aAAa;IACb,kBAAkB;IAClB,yBAAiB;YAAjB,iBAAiB;IACjB,sBAAsB;IACtB,WAAW;AACf;AACA;IACI,uBAAuB;AAC3B;;AAEA;IACI,SAAS;IACT,eAAe;IACf,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,SAAS;IACT,eAAe;IACf,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,SAAS;IACT,eAAe;IACf,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,oBAAoB;IACpB,aAAa;IACb,iBAAiB;IACjB,kBAAkB;IAClB,sBAAsB;IACtB,YAAY;IACZ,4BAA4B;IAC5B,WAAW;IACX,cAAc;AAClB","sourcesContent":[".MdManual {\n    height: 100%;\n    overflow: auto;\n    padding: 10px;\n    border-radius: 5px;\n    user-select: text;\n    border-radius: inherit;\n    color: #444;\n}\n.MdManual A {\n    color: rgb(0, 105, 192);\n}\n\n.MdManual H1 {\n    margin: 0;\n    font-size: 20px;\n    font-weight: normal;\n    color: black;\n}\n\n.MdManual H2 {\n    margin: 0;\n    font-size: 18px;\n    font-weight: normal;\n    color: black;\n}\n\n.MdManual H3 {\n    margin: 0;\n    font-size: 17px;\n    font-weight: normal;\n    color: black;\n}\n\n.MdManual CODE {\n    background: #e1f5fe;\n    display: inline-flex;\n    padding: 15px;\n    margin-left: 10px;\n    border-radius: 5px;\n    border: solid 2px #000;\n    color: black;\n    font-family: \"IBM Plex Mono\";\n    width: 100%;\n    overflow: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
