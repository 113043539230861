// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tabs {
    width: inherit;
    height: inherit;
    display: flex;
    flex-direction: column;
    border-radius: inherit;
}

.Tabs > .Navigation {
    box-shadow: 0px 8px 14px -13px rgba(66, 68, 90, 1);
    display: flex;
    height: 45px;
    z-index: 10;
    position: sticky;
    top: 0;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    padding-left: 20px;
}

.Tabs > .Navigation > .Tab {
    padding: 13px 10px 10px 10px;
    flex: 1 1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
}

.Tabs .TabContent {
    height: inherit;
    flex: 1 1;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: inherit;
}`, "",{"version":3,"sources":["webpack://./src/components/elements/tabs/Tabs.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;IACf,aAAa;IACb,sBAAsB;IACtB,sBAAsB;AAC1B;;AAEA;IAGI,kDAAkD;IAClD,aAAa;IACb,YAAY;IACZ,WAAW;IACX,gBAAgB;IAChB,MAAM;IACN,+BAA+B;IAC/B,gCAAgC;IAChC,kBAAkB;AACtB;;AAEA;IACI,4BAA4B;IAC5B,SAAO;IACP,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,yBAAyB;AAC7B;;AAEA;IACI,eAAe;IACf,SAAO;IACP,gBAAgB;IAChB,kBAAkB;IAClB,sBAAsB;AAC1B","sourcesContent":[".Tabs {\n    width: inherit;\n    height: inherit;\n    display: flex;\n    flex-direction: column;\n    border-radius: inherit;\n}\n\n.Tabs > .Navigation {\n    -webkit-box-shadow: 0px 8px 14px -13px rgba(66, 68, 90, 1);\n    -moz-box-shadow: 0px 8px 14px -13px rgba(66, 68, 90, 1);\n    box-shadow: 0px 8px 14px -13px rgba(66, 68, 90, 1);\n    display: flex;\n    height: 45px;\n    z-index: 10;\n    position: sticky;\n    top: 0;\n    border-top-left-radius: inherit;\n    border-top-right-radius: inherit;\n    padding-left: 20px;\n}\n\n.Tabs > .Navigation > .Tab {\n    padding: 13px 10px 10px 10px;\n    flex: 1;\n    cursor: pointer;\n    display: flex;\n    justify-content: center;\n    text-transform: uppercase;\n}\n\n.Tabs .TabContent {\n    height: inherit;\n    flex: 1;\n    overflow-y: auto;\n    overflow-x: hidden;\n    border-radius: inherit;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
