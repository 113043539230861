// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EventRow {
    font-weight: 400;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-top: 1px solid #aaa;
    animation-name: moveUpAndOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: .3s;
}

.EventRow:hover {
    background-color: #eee;
    box-shadow: 0px 8px 14px -13px rgba(66, 68, 90, 1);
}

.EventRow .Header {
    display: flex;
    flex-direction: row;
}

.EventRow .Header > DIV {
    padding: 3px;
    line-height: 1.7em;
}

.EventRow .Header DIV.Toggle {
    padding-top: 5px;
    cursor: pointer;
    width: 30px;
}

.EventRow .Header > DIV.Timestamp {
    display: flex;
    flex-direction: column;
    padding-top: 6px;
    min-width: 220px;
    max-width: 220px;
    border-right: solid 1px #cecece;
    justify-content: space-between;
    align-items: flex-end;
}

.EventRow .Header > DIV.Timestamp DIV {
    overflow: hidden;
    text-overflow: ellipsis;
}

.EventRow .Header > DIV.Data {
    padding-right: 10px;
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/elements/lists/rows/ObjectRow.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,0BAA0B;IAC1B,gCAAgC;IAChC,4BAA4B;IAC5B,mCAAmC;IACnC,uBAAuB;AAC3B;;AAEA;IACI,sBAAsB;IACtB,kDAAkD;AACtD;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;IAChB,+BAA+B;IAC/B,8BAA8B;IAC9B,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,uBAAuB;AAC3B;;AAEA;IACI,mBAAmB;IACnB,WAAW;AACf","sourcesContent":[".EventRow {\n    font-weight: 400;\n    display: flex;\n    flex-direction: column;\n    padding: 20px;\n    border-top: 1px solid #aaa;\n    animation-name: moveUpAndOpacity;\n    animation-iteration-count: 1;\n    animation-timing-function: ease-out;\n    animation-duration: .3s;\n}\n\n.EventRow:hover {\n    background-color: #eee;\n    box-shadow: 0px 8px 14px -13px rgba(66, 68, 90, 1);\n}\n\n.EventRow .Header {\n    display: flex;\n    flex-direction: row;\n}\n\n.EventRow .Header > DIV {\n    padding: 3px;\n    line-height: 1.7em;\n}\n\n.EventRow .Header DIV.Toggle {\n    padding-top: 5px;\n    cursor: pointer;\n    width: 30px;\n}\n\n.EventRow .Header > DIV.Timestamp {\n    display: flex;\n    flex-direction: column;\n    padding-top: 6px;\n    min-width: 220px;\n    max-width: 220px;\n    border-right: solid 1px #cecece;\n    justify-content: space-between;\n    align-items: flex-end;\n}\n\n.EventRow .Header > DIV.Timestamp DIV {\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n\n.EventRow .Header > DIV.Data {\n    padding-right: 10px;\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
