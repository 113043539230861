// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TopBar {
    display: flex;
    justify-content: space-between;
    padding: 2px 37px;
    border-radius: 15px;
    animation: moveDownAndOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: .3s;
}

.TopBar > .Title {
    font-weight: 400;
    font-size: 220%;
    margin: 0;
    color: #444;
    display: flex;
    align-items: center;
}

.TopBar > .Info {
    display: flex;
    white-space: nowrap;
    justify-content: center;
    align-items: flex-end;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/top/TopBar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,iBAAiB;IACjB,mBAAmB;IACnB,6BAA6B;IAC7B,4BAA4B;IAC5B,mCAAmC;IACnC,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,SAAS;IACT,WAAW;IACX,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,qBAAqB;AACzB","sourcesContent":[".TopBar {\n    display: flex;\n    justify-content: space-between;\n    padding: 2px 37px;\n    border-radius: 15px;\n    animation: moveDownAndOpacity;\n    animation-iteration-count: 1;\n    animation-timing-function: ease-out;\n    animation-duration: .3s;\n}\n\n.TopBar > .Title {\n    font-weight: 400;\n    font-size: 220%;\n    margin: 0;\n    color: #444;\n    display: flex;\n    align-items: center;\n}\n\n.TopBar > .Info {\n    display: flex;\n    white-space: nowrap;\n    justify-content: center;\n    align-items: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
