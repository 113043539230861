// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RightTabScroller {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    flex: 1 1;
}
.DetailActionButtons {
    padding: 10px;
    display: flex;
    flex-direction: row;
    margin-top: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/elements/details/Details.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,SAAS;AACb;AACA;IACI,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":[".RightTabScroller {\n    height: 100%;\n    overflow-y: scroll;\n    overflow-x: hidden;\n    flex: 1 1;\n}\n.DetailActionButtons {\n    padding: 10px;\n    display: flex;\n    flex-direction: row;\n    margin-top: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
