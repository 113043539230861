// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NeedHelpButton {
    padding: 20px;
    width: 350px;
}

.NeedHelpButton > DIV:not(:first-child) {
    margin-top: 20px
}`, "",{"version":3,"sources":["webpack://./src/components/elements/misc/NeedHelpButton.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;AAChB;;AAEA;IACI;AACJ","sourcesContent":[".NeedHelpButton {\n    padding: 20px;\n    width: 350px;\n}\n\n.NeedHelpButton > DIV:not(:first-child) {\n    margin-top: 20px\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
