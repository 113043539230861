// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErrorsBox {
    border: solid 2px #d81b60;
    padding: 15px;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    color: #fff;
    background-color: #d81b60;
    width: 100%;
}

.NotFullErrorBox {
    border-radius: 4px;
}

.ErrorsBox > .ErrorDetails {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.ErrorsBox .ErrorDetails > .Header {
    font-size: 120%;
    text-decoration: underline;
    font-weight: 400;
    margin-left: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/errors/ErrorsBox.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,0BAA0B;IAC1B,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".ErrorsBox {\n    border: solid 2px #d81b60;\n    padding: 15px;\n    overflow-x: auto;\n    display: flex;\n    flex-direction: row;\n    color: #fff;\n    background-color: #d81b60;\n    width: 100%;\n}\n\n.NotFullErrorBox {\n    border-radius: 4px;\n}\n\n.ErrorsBox > .ErrorDetails {\n    display: flex;\n    flex-direction: column;\n    margin-left: 10px;\n}\n\n.ErrorsBox .ErrorDetails > .Header {\n    font-size: 120%;\n    text-decoration: underline;\n    font-weight: 400;\n    margin-left: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
