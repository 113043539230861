// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SquareCard {
    display: inline-flex;
    align-items: center;
    border: solid rgba(225,225,225, 0.8) 1px;
    background: rgba(225,225,225, 0.8);
    cursor: pointer;
    width: 190px;
    height: 190px;
    max-height: 200px;
    margin: 6px;
    padding: 15px;
    border-radius: 10px;
    flex-direction: column;
    justify-content: center;
    font-size: 120%;
    font-weight: 400;
    position: relative;
}

.SquareCard:hover {
    border: solid #ccc 1px;
    background: whitesmoke;
}

.SquareCard .name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    font-size: 110%;
    text-align: center;
}

.SquareCard .desc {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font-size: 70%;
    text-align: center;
    max-height: 60px;
    color: #444;
}

.SquareCard .tags {
    font-size: 80%;
}

.SquareCard .icon {
    margin: 10px;
    color: #444;
}

.SquareCard .enabled {
    border-bottom: #00c853 solid 7px;
}

.SquareCard .disabled {
    border-bottom: #d81b60 solid 7px;
}`, "",{"version":3,"sources":["webpack://./src/components/elements/lists/cards/SquareCard.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,mBAAmB;IACnB,wCAAwC;IACxC,kCAAkC;IAClC,eAAe;IACf,YAAY;IACZ,aAAa;IACb,iBAAiB;IACjB,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,uBAAuB;IACvB,eAAe;IACf,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,sBAAsB;IACtB,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,uBAAuB;IACvB,WAAW;IACX,cAAc;IACd,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,gCAAgC;AACpC","sourcesContent":[".SquareCard {\n    display: inline-flex;\n    align-items: center;\n    border: solid rgba(225,225,225, 0.8) 1px;\n    background: rgba(225,225,225, 0.8);\n    cursor: pointer;\n    width: 190px;\n    height: 190px;\n    max-height: 200px;\n    margin: 6px;\n    padding: 15px;\n    border-radius: 10px;\n    flex-direction: column;\n    justify-content: center;\n    font-size: 120%;\n    font-weight: 400;\n    position: relative;\n}\n\n.SquareCard:hover {\n    border: solid #ccc 1px;\n    background: whitesmoke;\n}\n\n.SquareCard .name {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    width: 100%;\n    font-size: 110%;\n    text-align: center;\n}\n\n.SquareCard .desc {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    width: 100%;\n    font-size: 70%;\n    text-align: center;\n    max-height: 60px;\n    color: #444;\n}\n\n.SquareCard .tags {\n    font-size: 80%;\n}\n\n.SquareCard .icon {\n    margin: 10px;\n    color: #444;\n}\n\n.SquareCard .enabled {\n    border-bottom: #00c853 solid 7px;\n}\n\n.SquareCard .disabled {\n    border-bottom: #d81b60 solid 7px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
