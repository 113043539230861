// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FilterAddForm {
    width: calc(100% - 40px);
}
.FilterAddForm > DIV {
    display: grid;
    grid-template-columns: auto 215px;
    width: inherit;
    align-items: center;
    width: 100%;

}

.FilterAddForm > DIV.FullGrid {
    display: flex !important;
    grid-template-columns: none;
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/elements/forms/inputs/FilterAddForm.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;AAC5B;AACA;IACI,aAAa;IACb,iCAAiC;IACjC,cAAc;IACd,mBAAmB;IACnB,WAAW;;AAEf;;AAEA;IACI,wBAAwB;IACxB,2BAA2B;IAC3B,WAAW;AACf","sourcesContent":[".FilterAddForm {\n    width: calc(100% - 40px);\n}\n.FilterAddForm > DIV {\n    display: grid;\n    grid-template-columns: auto 215px;\n    width: inherit;\n    align-items: center;\n    width: 100%;\n\n}\n\n.FilterAddForm > DIV.FullGrid {\n    display: flex !important;\n    grid-template-columns: none;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
