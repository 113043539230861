// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ObjectList {
    display: grid;
    width: 100%;
    padding: 10px;
    border-bottom: 2px transparent solid;
}

.EndOfList {
    /*border-bottom: 2px #006db3 solid !important;*/
}

.ObjectList > .Header {
    display: flex;
    flex-direction: row;
    text-transform: uppercase;
    font-weight: 600;
    height: 30px;
    align-items: center;
}

.ObjectList .Header > .Timestamp {
    width: 230px;
    padding: 2px 5px 5px 15px;
}

.ObjectList .Header > .Data {
    padding: 2px 5px 5px 15px;
}

.ObjectList > .DataContent {
    height: 100%;
    overflow-y: scroll;
}

.ObjectListStatus {
    height: 21px;
    border-top: 1px solid #ccc;
}`, "",{"version":3,"sources":["webpack://./src/components/elements/lists/ObjectList.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,aAAa;IACb,oCAAoC;AACxC;;AAEA;IACI,+CAA+C;AACnD;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,gBAAgB;IAChB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,0BAA0B;AAC9B","sourcesContent":[".ObjectList {\n    display: grid;\n    width: 100%;\n    padding: 10px;\n    border-bottom: 2px transparent solid;\n}\n\n.EndOfList {\n    /*border-bottom: 2px #006db3 solid !important;*/\n}\n\n.ObjectList > .Header {\n    display: flex;\n    flex-direction: row;\n    text-transform: uppercase;\n    font-weight: 600;\n    height: 30px;\n    align-items: center;\n}\n\n.ObjectList .Header > .Timestamp {\n    width: 230px;\n    padding: 2px 5px 5px 15px;\n}\n\n.ObjectList .Header > .Data {\n    padding: 2px 5px 5px 15px;\n}\n\n.ObjectList > .DataContent {\n    height: 100%;\n    overflow-y: scroll;\n}\n\n.ObjectListStatus {\n    height: 21px;\n    border-top: 1px solid #ccc;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
