// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Rows {
    width: inherit;
    height: inherit;
    display: flex;
    flex-direction: row;
}`, "",{"version":3,"sources":["webpack://./src/components/elements/misc/Rows.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;IACf,aAAa;IACb,mBAAmB;AACvB","sourcesContent":[".Rows {\n    width: inherit;\n    height: inherit;\n    display: flex;\n    flex-direction: row;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
