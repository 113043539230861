// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainContent {
    display: flex;
    flex-direction: row;
    width:100%;
    height: 100%;
    gap: 10px;
    padding: 10px;
}

.MainContent > .MainPane {
    width: inherit;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/MainContent.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,UAAU;IACV,YAAY;IACZ,SAAS;IACT,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,mBAAmB;AACvB","sourcesContent":[".MainContent {\n    display: flex;\n    flex-direction: row;\n    width:100%;\n    height: 100%;\n    gap: 10px;\n    padding: 10px;\n}\n\n.MainContent > .MainPane {\n    width: inherit;\n    overflow: auto;\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    border-radius: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
